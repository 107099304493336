html,
body,
header,
.jarallax {
  height: 100vh;
}

@media (min-width: 560px) and (max-width: 740px) {
  html,
  body,
  header,
  .jarallax {
    height: 600px;
  }
}
